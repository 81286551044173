import { Box, Button, LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { APP_ENV } from 'common/env';
import dayjs from 'dayjs';
import React from 'react';
import { BILLING_SCHEDULES_TO_PRICES, DATE_FORMAT } from 'src/constants';
import {
  BillingProfile,
  useAdminChargeCustomerMutation,
  useGetPaymentHistoryQuery,
} from 'src/web-types';

interface PaymentHistoryProps {
  billingProfile: BillingProfile;
}

interface PaymentLineProps {
  date: number | string;
  amount: number;
  pending?: boolean;
}

const AdminTestPanel: React.FC<PaymentHistoryProps> = ({ billingProfile }) => {
  if (!billingProfile || !billingProfile.stripeCustomerId) return null;

  const [adminChargeCustomerMutation] = useAdminChargeCustomerMutation({
    variables: {
      stripeCustomerId: billingProfile.stripeCustomerId,
      amount: BILLING_SCHEDULES_TO_PRICES[billingProfile.billingSchedule],
    },
    refetchQueries: ['GetPaymentHistory'],
  });

  return (
    <Box>
      <Button onClick={() => adminChargeCustomerMutation()}>Charge this bitch</Button>
    </Box>
  );
};

const PaymentLine: React.FC<PaymentLineProps> = ({ date, amount, pending }) => (
  <tr>
    <td>{dayjs(date).format(DATE_FORMAT) + `${pending ? ' (scheduled)' : ''}`}</td>
    <td style={{ padding: '0 10px' }}>--------</td>
    <td>{`$${amount}`}</td>
  </tr>
);

export const PaymentHistory: React.FC<PaymentHistoryProps> = ({ billingProfile }) => {
  if (!billingProfile || !billingProfile.stripeCustomerId) return null;

  const { data, loading, error } = useGetPaymentHistoryQuery({
    variables: {
      stripeCustomerId: billingProfile.stripeCustomerId,
    },
  });

  if (loading) return <LinearProgress />;
  if (error)
    return (
      <Paper sx={{ p: 1, mb: 10, mt: 1 }}>
        <h3>{error}</h3>
      </Paper>
    );

  return (
    <Paper sx={{ p: 1, mb: 10, mt: 1 }}>
      <Box sx={{ mb: 1 }}>
        <Stack>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Payment History
          </Typography>
          {APP_ENV === 'local' && <AdminTestPanel billingProfile={billingProfile} />}
          <Box sx={{ mb: 3 }}>
            <table>
              <PaymentLine
                pending={true}
                date={billingProfile.paidUntil}
                amount={BILLING_SCHEDULES_TO_PRICES[billingProfile.billingSchedule]}
              />
            </table>
            {data?.getPaymentHistory?.map(
              (ph) =>
                ph &&
                ph.amount &&
                ph.date && <PaymentLine amount={ph.amount / 100} date={ph.date * 1000} />
            )}
          </Box>
        </Stack>
      </Box>
    </Paper>
  );
};
