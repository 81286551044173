import React, { createContext, useContext } from 'react';
import { MeQueryHookResult, useMeLazyQuery, User } from 'src/web-types';

interface AuthReturn {
  user?: User;
  getMe: () => MeQueryHookResult;
  loading: boolean;
}
const AuthContext = createContext({});

export const AuthProvider: React.FC = (props) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext) as AuthReturn;
};

function useProvideAuth() {
  const [getMe, { data, loading }] = useMeLazyQuery({
    fetchPolicy: 'network-only',
    onError: (data) => {
      console.error(data);
    },
  });

  return {
    getMe,
    user: data?.me,
    loading: loading,
  };
}

export type AuthType = ReturnType<typeof useProvideAuth>;
