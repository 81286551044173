import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
};

export type BillingProfile = {
  __typename?: 'BillingProfile';
  billingSchedule: BillingSchedule;
  freeTrialEnds: Scalars['DateTime'];
  paidUntil: Scalars['DateTime'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
  teamName?: Maybe<Scalars['String']>;
};

export type BillingSchedule =
  | 'Monthly'
  | 'Yearly';

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  success: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminChargeCustomer?: Maybe<PaymentRecord>;
  createBillingProfile: BillingProfile;
  createSetupIntent: SetupIntentResponse;
  logout: LogoutResponse;
  oauthExchange?: Maybe<OauthResponse>;
  root?: Maybe<Scalars['String']>;
  updateBillingProfile: BillingProfile;
};


export type MutationAdminChargeCustomerArgs = {
  amount: Scalars['Int'];
  stripeCustomerId: Scalars['String'];
};


export type MutationCreateBillingProfileArgs = {
  billingSchedule: BillingSchedule;
};


export type MutationOauthExchangeArgs = {
  code: Scalars['String'];
  platform: Scalars['String'];
  state: Scalars['String'];
};


export type MutationUpdateBillingProfileArgs = {
  billingSchedule: BillingSchedule;
};

export type OauthLinkResponse = {
  __typename?: 'OauthLinkResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export type OauthResponse = {
  __typename?: 'OauthResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

export type PaymentRecord = {
  __typename?: 'PaymentRecord';
  amount: Scalars['Int'];
  date: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  adminOauthLink?: Maybe<OauthLinkResponse>;
  getBillingProfile?: Maybe<BillingProfile>;
  getPaymentHistory?: Maybe<Array<Maybe<PaymentRecord>>>;
  getPaymentMethod?: Maybe<StripePaymentMethod>;
  me?: Maybe<User>;
  oauthLink?: Maybe<OauthLinkResponse>;
  root?: Maybe<Scalars['String']>;
};


export type QueryAdminOauthLinkArgs = {
  platform: Scalars['String'];
};


export type QueryGetPaymentHistoryArgs = {
  stripeCustomerId: Scalars['String'];
};


export type QueryGetPaymentMethodArgs = {
  stripeCustomerId: Scalars['String'];
};


export type QueryOauthLinkArgs = {
  platform: Scalars['String'];
};

export type SetupIntentResponse = {
  __typename?: 'SetupIntentResponse';
  clientSecret: Scalars['String'];
};

export type StripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  brand?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  idToken?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
  teamName?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type BillingProfileFieldsFragment = { __typename?: 'BillingProfile', teamId: string, billingSchedule: BillingSchedule, paidUntil: string, freeTrialEnds: string, stripeCustomerId?: string | null | undefined, teamName?: string | null | undefined };

export type GetBillingProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBillingProfileQuery = { __typename?: 'Query', getBillingProfile?: { __typename?: 'BillingProfile', teamId: string, billingSchedule: BillingSchedule, paidUntil: string, freeTrialEnds: string, stripeCustomerId?: string | null | undefined, teamName?: string | null | undefined } | null | undefined };

export type GetPaymentMethodQueryVariables = Exact<{
  stripeCustomerId: Scalars['String'];
}>;


export type GetPaymentMethodQuery = { __typename?: 'Query', getPaymentMethod?: { __typename?: 'StripePaymentMethod', id?: string | null | undefined, brand?: string | null | undefined, last4?: string | null | undefined } | null | undefined };

export type GetPaymentHistoryQueryVariables = Exact<{
  stripeCustomerId: Scalars['String'];
}>;


export type GetPaymentHistoryQuery = { __typename?: 'Query', getPaymentHistory?: Array<{ __typename?: 'PaymentRecord', date: number, amount: number } | null | undefined> | null | undefined };

export type UpdateBillingProfileMutationVariables = Exact<{
  billingSchedule: BillingSchedule;
}>;


export type UpdateBillingProfileMutation = { __typename?: 'Mutation', updateBillingProfile: { __typename?: 'BillingProfile', teamId: string, billingSchedule: BillingSchedule, paidUntil: string, freeTrialEnds: string, stripeCustomerId?: string | null | undefined, teamName?: string | null | undefined } };

export type CreateSetupIntentMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateSetupIntentMutation = { __typename?: 'Mutation', createSetupIntent: { __typename?: 'SetupIntentResponse', clientSecret: string } };

export type AdminChargeCustomerMutationVariables = Exact<{
  stripeCustomerId: Scalars['String'];
  amount: Scalars['Int'];
}>;


export type AdminChargeCustomerMutation = { __typename?: 'Mutation', adminChargeCustomer?: { __typename?: 'PaymentRecord', date: number, amount: number } | null | undefined };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', teamId: string, name: string, email: string, picture?: string | null | undefined, teamName?: string | null | undefined, userId: string } | null | undefined };

export type AdminOauthLinkQueryVariables = Exact<{
  platform: Scalars['String'];
}>;


export type AdminOauthLinkQuery = { __typename?: 'Query', adminOauthLink?: { __typename?: 'OauthLinkResponse', success: boolean, url?: string | null | undefined, error?: string | null | undefined } | null | undefined };

export type OauthLinkQueryVariables = Exact<{
  platform: Scalars['String'];
}>;


export type OauthLinkQuery = { __typename?: 'Query', oauthLink?: { __typename?: 'OauthLinkResponse', success: boolean, url?: string | null | undefined, error?: string | null | undefined } | null | undefined };

export type OauthExchangeMutationVariables = Exact<{
  code: Scalars['String'];
  state: Scalars['String'];
  platform: Scalars['String'];
}>;


export type OauthExchangeMutation = { __typename?: 'Mutation', oauthExchange?: { __typename?: 'OauthResponse', success: boolean, token?: string | null | undefined, error?: string | null | undefined } | null | undefined };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: { __typename?: 'LogoutResponse', success: boolean } };

export const BillingProfileFieldsFragmentDoc = gql`
    fragment BillingProfileFields on BillingProfile {
  teamId
  billingSchedule
  paidUntil
  freeTrialEnds
  stripeCustomerId
  teamName
}
    `;
export const GetBillingProfileDocument = gql`
    query GetBillingProfile {
  getBillingProfile {
    ...BillingProfileFields
  }
}
    ${BillingProfileFieldsFragmentDoc}`;

/**
 * __useGetBillingProfileQuery__
 *
 * To run a query within a React component, call `useGetBillingProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBillingProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetBillingProfileQuery, GetBillingProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillingProfileQuery, GetBillingProfileQueryVariables>(GetBillingProfileDocument, options);
      }
export function useGetBillingProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillingProfileQuery, GetBillingProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillingProfileQuery, GetBillingProfileQueryVariables>(GetBillingProfileDocument, options);
        }
export type GetBillingProfileQueryHookResult = ReturnType<typeof useGetBillingProfileQuery>;
export type GetBillingProfileLazyQueryHookResult = ReturnType<typeof useGetBillingProfileLazyQuery>;
export type GetBillingProfileQueryResult = Apollo.QueryResult<GetBillingProfileQuery, GetBillingProfileQueryVariables>;
export const GetPaymentMethodDocument = gql`
    query GetPaymentMethod($stripeCustomerId: String!) {
  getPaymentMethod(stripeCustomerId: $stripeCustomerId) {
    id
    brand
    last4
  }
}
    `;

/**
 * __useGetPaymentMethodQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodQuery({
 *   variables: {
 *      stripeCustomerId: // value for 'stripeCustomerId'
 *   },
 * });
 */
export function useGetPaymentMethodQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentMethodQuery, GetPaymentMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentMethodQuery, GetPaymentMethodQueryVariables>(GetPaymentMethodDocument, options);
      }
export function useGetPaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentMethodQuery, GetPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentMethodQuery, GetPaymentMethodQueryVariables>(GetPaymentMethodDocument, options);
        }
export type GetPaymentMethodQueryHookResult = ReturnType<typeof useGetPaymentMethodQuery>;
export type GetPaymentMethodLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodLazyQuery>;
export type GetPaymentMethodQueryResult = Apollo.QueryResult<GetPaymentMethodQuery, GetPaymentMethodQueryVariables>;
export const GetPaymentHistoryDocument = gql`
    query GetPaymentHistory($stripeCustomerId: String!) {
  getPaymentHistory(stripeCustomerId: $stripeCustomerId) {
    date
    amount
  }
}
    `;

/**
 * __useGetPaymentHistoryQuery__
 *
 * To run a query within a React component, call `useGetPaymentHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentHistoryQuery({
 *   variables: {
 *      stripeCustomerId: // value for 'stripeCustomerId'
 *   },
 * });
 */
export function useGetPaymentHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentHistoryQuery, GetPaymentHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentHistoryQuery, GetPaymentHistoryQueryVariables>(GetPaymentHistoryDocument, options);
      }
export function useGetPaymentHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentHistoryQuery, GetPaymentHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentHistoryQuery, GetPaymentHistoryQueryVariables>(GetPaymentHistoryDocument, options);
        }
export type GetPaymentHistoryQueryHookResult = ReturnType<typeof useGetPaymentHistoryQuery>;
export type GetPaymentHistoryLazyQueryHookResult = ReturnType<typeof useGetPaymentHistoryLazyQuery>;
export type GetPaymentHistoryQueryResult = Apollo.QueryResult<GetPaymentHistoryQuery, GetPaymentHistoryQueryVariables>;
export const UpdateBillingProfileDocument = gql`
    mutation UpdateBillingProfile($billingSchedule: BillingSchedule!) {
  updateBillingProfile(billingSchedule: $billingSchedule) {
    ...BillingProfileFields
  }
}
    ${BillingProfileFieldsFragmentDoc}`;
export type UpdateBillingProfileMutationFn = Apollo.MutationFunction<UpdateBillingProfileMutation, UpdateBillingProfileMutationVariables>;

/**
 * __useUpdateBillingProfileMutation__
 *
 * To run a mutation, you first call `useUpdateBillingProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingProfileMutation, { data, loading, error }] = useUpdateBillingProfileMutation({
 *   variables: {
 *      billingSchedule: // value for 'billingSchedule'
 *   },
 * });
 */
export function useUpdateBillingProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBillingProfileMutation, UpdateBillingProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBillingProfileMutation, UpdateBillingProfileMutationVariables>(UpdateBillingProfileDocument, options);
      }
export type UpdateBillingProfileMutationHookResult = ReturnType<typeof useUpdateBillingProfileMutation>;
export type UpdateBillingProfileMutationResult = Apollo.MutationResult<UpdateBillingProfileMutation>;
export type UpdateBillingProfileMutationOptions = Apollo.BaseMutationOptions<UpdateBillingProfileMutation, UpdateBillingProfileMutationVariables>;
export const CreateSetupIntentDocument = gql`
    mutation CreateSetupIntent {
  createSetupIntent {
    clientSecret
  }
}
    `;
export type CreateSetupIntentMutationFn = Apollo.MutationFunction<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>;

/**
 * __useCreateSetupIntentMutation__
 *
 * To run a mutation, you first call `useCreateSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSetupIntentMutation, { data, loading, error }] = useCreateSetupIntentMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateSetupIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>(CreateSetupIntentDocument, options);
      }
export type CreateSetupIntentMutationHookResult = ReturnType<typeof useCreateSetupIntentMutation>;
export type CreateSetupIntentMutationResult = Apollo.MutationResult<CreateSetupIntentMutation>;
export type CreateSetupIntentMutationOptions = Apollo.BaseMutationOptions<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>;
export const AdminChargeCustomerDocument = gql`
    mutation AdminChargeCustomer($stripeCustomerId: String!, $amount: Int!) {
  adminChargeCustomer(stripeCustomerId: $stripeCustomerId, amount: $amount) {
    date
    amount
  }
}
    `;
export type AdminChargeCustomerMutationFn = Apollo.MutationFunction<AdminChargeCustomerMutation, AdminChargeCustomerMutationVariables>;

/**
 * __useAdminChargeCustomerMutation__
 *
 * To run a mutation, you first call `useAdminChargeCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminChargeCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminChargeCustomerMutation, { data, loading, error }] = useAdminChargeCustomerMutation({
 *   variables: {
 *      stripeCustomerId: // value for 'stripeCustomerId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useAdminChargeCustomerMutation(baseOptions?: Apollo.MutationHookOptions<AdminChargeCustomerMutation, AdminChargeCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminChargeCustomerMutation, AdminChargeCustomerMutationVariables>(AdminChargeCustomerDocument, options);
      }
export type AdminChargeCustomerMutationHookResult = ReturnType<typeof useAdminChargeCustomerMutation>;
export type AdminChargeCustomerMutationResult = Apollo.MutationResult<AdminChargeCustomerMutation>;
export type AdminChargeCustomerMutationOptions = Apollo.BaseMutationOptions<AdminChargeCustomerMutation, AdminChargeCustomerMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    teamId
    name
    email
    picture
    teamName
    userId
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const AdminOauthLinkDocument = gql`
    query AdminOauthLink($platform: String!) {
  adminOauthLink(platform: $platform) {
    success
    url
    error
  }
}
    `;

/**
 * __useAdminOauthLinkQuery__
 *
 * To run a query within a React component, call `useAdminOauthLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOauthLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOauthLinkQuery({
 *   variables: {
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useAdminOauthLinkQuery(baseOptions: Apollo.QueryHookOptions<AdminOauthLinkQuery, AdminOauthLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminOauthLinkQuery, AdminOauthLinkQueryVariables>(AdminOauthLinkDocument, options);
      }
export function useAdminOauthLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminOauthLinkQuery, AdminOauthLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminOauthLinkQuery, AdminOauthLinkQueryVariables>(AdminOauthLinkDocument, options);
        }
export type AdminOauthLinkQueryHookResult = ReturnType<typeof useAdminOauthLinkQuery>;
export type AdminOauthLinkLazyQueryHookResult = ReturnType<typeof useAdminOauthLinkLazyQuery>;
export type AdminOauthLinkQueryResult = Apollo.QueryResult<AdminOauthLinkQuery, AdminOauthLinkQueryVariables>;
export const OauthLinkDocument = gql`
    query OauthLink($platform: String!) {
  oauthLink(platform: $platform) {
    success
    url
    error
  }
}
    `;

/**
 * __useOauthLinkQuery__
 *
 * To run a query within a React component, call `useOauthLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useOauthLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOauthLinkQuery({
 *   variables: {
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useOauthLinkQuery(baseOptions: Apollo.QueryHookOptions<OauthLinkQuery, OauthLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OauthLinkQuery, OauthLinkQueryVariables>(OauthLinkDocument, options);
      }
export function useOauthLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OauthLinkQuery, OauthLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OauthLinkQuery, OauthLinkQueryVariables>(OauthLinkDocument, options);
        }
export type OauthLinkQueryHookResult = ReturnType<typeof useOauthLinkQuery>;
export type OauthLinkLazyQueryHookResult = ReturnType<typeof useOauthLinkLazyQuery>;
export type OauthLinkQueryResult = Apollo.QueryResult<OauthLinkQuery, OauthLinkQueryVariables>;
export const OauthExchangeDocument = gql`
    mutation OauthExchange($code: String!, $state: String!, $platform: String!) {
  oauthExchange(code: $code, state: $state, platform: $platform) {
    success
    token
    error
  }
}
    `;
export type OauthExchangeMutationFn = Apollo.MutationFunction<OauthExchangeMutation, OauthExchangeMutationVariables>;

/**
 * __useOauthExchangeMutation__
 *
 * To run a mutation, you first call `useOauthExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOauthExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oauthExchangeMutation, { data, loading, error }] = useOauthExchangeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      state: // value for 'state'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useOauthExchangeMutation(baseOptions?: Apollo.MutationHookOptions<OauthExchangeMutation, OauthExchangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OauthExchangeMutation, OauthExchangeMutationVariables>(OauthExchangeDocument, options);
      }
export type OauthExchangeMutationHookResult = ReturnType<typeof useOauthExchangeMutation>;
export type OauthExchangeMutationResult = Apollo.MutationResult<OauthExchangeMutation>;
export type OauthExchangeMutationOptions = Apollo.BaseMutationOptions<OauthExchangeMutation, OauthExchangeMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    success
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;