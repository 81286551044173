export const LOCAL_STORAGE_KEY = '___SLACKTUNES___';

export const saveStateSlice = (stateSlice: Record<string, string>): boolean => {
  try {
    let existingState = loadState();
    if (!Boolean(existingState)) {
      existingState = {};
    }
    const serializedState = JSON.stringify({ ...existingState, ...stateSlice });
    localStorage.setItem(LOCAL_STORAGE_KEY, serializedState);

    return true;
  } catch (err) {
    return false;
  }
};

export const loadState = (): Record<string, string> | undefined => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
