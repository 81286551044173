import { Box, Checkbox, Grid, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { BILLING_SCHEDULES_TO_PRICES } from 'src/constants';
import { BillingProfile, BillingSchedule, useUpdateBillingProfileMutation } from 'src/web-types';

interface BillingScheduleSelectProps {
  price: number;
  period: BillingSchedule;
  checked: boolean;
  loading: boolean;
  handleChange: () => void;
}

const BillingScheduleSelect: React.FC<BillingScheduleSelectProps> = ({
  price,
  period,
  checked,
  loading,
  handleChange,
}) => {
  return (
    <Paper
      onClick={() => !loading && handleChange()}
      sx={{
        p: 2,
        pt: 1,
        pb: 1,
        color: loading ? '#424242' : 'white',
        backgroundColor: loading ? 'black' : '#30313d',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Grid container spacing={4} sx={{ alignItems: 'center ' }}>
        <Grid item>
          <Typography variant="h6">{period}</Typography>
        </Grid>
        <Grid item>
          <Checkbox checked={checked} />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ alignItems: 'baseline' }}>
        <Grid item>
          <Typography variant="h3">{`$${price}`}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">{` / ${period.toLowerCase().slice(0, -2)}`}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const BillingScheduleSection: React.FC<{ billingProfile: BillingProfile }> = ({
  billingProfile,
}) => {
  const [updateBillingProfile, { data, loading, error }] = useUpdateBillingProfileMutation();

  function handleBillingScheduleChange(billingSchedule: BillingSchedule) {
    updateBillingProfile({
      variables: { billingSchedule },
      refetchQueries: ['GetBillingProfile'],
    });
  }

  return (
    <Paper sx={{ p: 1, mb: 1 }}>
      <Box sx={{ mb: 1 }}>
        <Stack>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Billing Plan
          </Typography>
          <Grid container spacing={6} sx={{ mb: 2 }}>
            {error && <Typography variant="h6">{error}</Typography>}
            {Object.keys(BILLING_SCHEDULES_TO_PRICES).map((bs) => (
              <Grid key={bs} item>
                <BillingScheduleSelect
                  price={BILLING_SCHEDULES_TO_PRICES[bs as BillingSchedule]}
                  period={bs as BillingSchedule}
                  checked={
                    (data?.updateBillingProfile?.billingSchedule ||
                      billingProfile.billingSchedule) === bs
                  }
                  loading={loading}
                  handleChange={() => handleBillingScheduleChange(bs as BillingSchedule)}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Box>
    </Paper>
  );
};
