import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { WEB_HOST } from 'common/env';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  BillingProfile,
  useCreateSetupIntentMutation,
  useGetPaymentMethodQuery,
} from 'src/web-types';
import _Stripe from 'stripe';

const stripePromise = loadStripe(
  'pk_test_51KTAVEBNWV0T1ko49GoM1QZpD49gcBbTA1hc0ZcNZBj7hp9LXPeAVXinIP2CwCpuqlxB7aJcMjRVAg0UepD63R6u00zxsFXlx4'
);

const PaymentCardForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [submitError, setSubmitError] = useState<string | undefined>(undefined);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      console.log('NO STRIPE OR NO ELEMENTS');
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error: sError } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${WEB_HOST}/billing`,
      },
    });

    if (sError) {
      setSubmitError(sError.message);
    } else {
      console.log('SUCCESS, redirecting');
    }
  };

  return (
    <Box sx={{ p: 1 }}>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        {submitError && <Typography variant="h6">{submitError}</Typography>}
        <Button variant="outlined" type="submit" sx={{ mt: 1 }}>
          Submit
        </Button>
      </form>
    </Box>
  );
};

const SetupIntentProcessing: React.FC = () => {
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [intentStatus, setIntentStatus] = useState<_Stripe.SetupIntent.Status | undefined>();
  // this is for when Stripe redirects after setting up a payment
  // this should happen only very infrequently
  const clientSecret = new URLSearchParams(window.location.search).get(
    'setup_intent_client_secret'
  );

  useEffect(() => {
    if (clientSecret && stripe) {
      setLoading(true);
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        setIntentStatus(setupIntent?.status);
      });
    }
  }, [stripe, clientSecret]);

  if (loading) return <CircularProgress />;

  return !clientSecret || (clientSecret && intentStatus && intentStatus === 'succeeded') ? (
    <PaymentCardForm />
  ) : (
    <Typography>Error setting up payment. Please contact Slacktunes</Typography>
  );
};

const SetupIntentForm: React.FC = () => {
  const [createSetupIntent, { data, loading, error }] = useCreateSetupIntentMutation();

  if (loading) return <h3>Loading</h3>;
  if (error) return <h3>{JSON.stringify(error)}</h3>;

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Paper sx={{ p: 1 }}>
        <Typography variant="h6">Internal Error</Typography>
      </Paper>
    );
  }

  return (
    <Box>
      {loading ? (
        <CircularProgress />
      ) : data ? (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: data.createSetupIntent.clientSecret,
            appearance: {
              theme: 'night',
              labels: 'floating',
            },
          }}
        >
          <SetupIntentProcessing />
        </Elements>
      ) : (
        <Button variant="outlined" onClick={() => createSetupIntent()}>
          Set up payment card
        </Button>
      )}
    </Box>
  );
};

const PaymentCardDisplay: React.FC<{ card: any }> = ({ card }) => {
  return (
    <Box sx={{ p: 1, bgcolor: '#30313d', borderRadius: '7px' }}>
      <Grid container spacing={4}>
        <Grid item>
          <Typography variant="h5">{capitalize(card.brand)}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            {card.last4}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export const PaymentMethodSection: React.FC<{ billingProfile: BillingProfile }> = ({
  billingProfile,
}) => {
  const { data, loading, error } = useGetPaymentMethodQuery({
    variables: { stripeCustomerId: billingProfile.stripeCustomerId || '' },
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Paper sx={{ p: 1 }}>
        <Typography variant="h6">Internal Error</Typography>
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 1, pb: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Payment Method
      </Typography>
      {data?.getPaymentMethod?.id ? (
        <PaymentCardDisplay card={data.getPaymentMethod} />
      ) : (
        <SetupIntentForm />
      )}
    </Paper>
  );
};
