import { Container, Typography } from '@mui/material';
import { default as React } from 'react';

export interface WelcomeProps {}

export const Welcome: React.FC<WelcomeProps> = () => {
  return (
    <Container maxWidth="lg" sx={{ pt: '100px' }}>
      <Typography variant="body1">
        The Slackbot that keeps track of the music you share with your friends
      </Typography>
    </Container>
  );
};
