import { CircularProgress, Typography } from '@mui/material';
import { WEB_HOST } from 'common/env';
import React from 'react';
import { saveStateSlice } from 'src/auth/localState';
import { useLogoutMutation } from 'src/web-types';

export const Logout: React.FC = () => {
  const [logout, { data, loading }] = useLogoutMutation({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      saveStateSlice({ token: '' });
    },
  });

  function handleLogout() {
    logout();
  }

  if (loading) return <CircularProgress />;
  if (data?.logout?.success) window.location.href = WEB_HOST;

  return (
    <Typography
      variant="body1"
      sx={{ fontWeight: 600, mr: '20px', '&:hover': { cursor: 'pointer' } }}
      onClick={handleLogout}
    >
      Logout
    </Typography>
  );
};
