type APP_ENV = 'local' | 'prod';
type NODE_ENV = 'development' | 'production';

export const APP_ENV = process.env.APP_ENV! as APP_ENV;
export const NODE_ENV = process.env.NODE_ENV! as NODE_ENV;

export const APP_VHOSTS = process.env.APP_VHOSTS?.split(',') || [];
export const AUTHORIZED_USERS = process.env.AUTHORIZED_USERS?.split(',') || [];

export const LOCAL_WEB_PORT = parseInt(process.env.LOCAL_WEB_PORT!, 10);
export const LOCAL_API_PORT = parseInt(process.env.LOCAL_API_PORT!, 10);
export const LOCAL_REDIS_PORT = parseInt(process.env.LOCAL_REDIS_PORT!, 10);

export const EXTERNAL_API_HOST = `https://${process.env.EXTERNAL_API_DOMAIN}`!;
export const INTERNAL_API_HOST =
  APP_ENV === 'local'
    ? `http://${process.env.INTERNAL_API_DOMAIN}:${LOCAL_API_PORT}`
    : `https://${process.env.INTERNAL_API_DOMAIN}`;
export const WEB_HOST =
  APP_ENV === 'local'
    ? `https://${process.env.WEB_DOMAIN}:${LOCAL_WEB_PORT}`
    : `https://${process.env.WEB_DOMAIN}`;

export const REDIS_HOST = APP_ENV === 'local' ? 'localhost' : 'redis';
export const REDIS_PORT = APP_ENV === 'local' ? LOCAL_REDIS_PORT : 6379;

export const JWT_SECRET = process.env.JWT_SECRET!;

export const STRIPE_KEY = process.env.STRIPE_KEY!;

export const SPOTIFY_CLIENT_ID = process.env.SPOTIFY_CLIENT_ID!;
export const SPOTIFY_CLIENT_SECRET = process.env.SPOTIFY_CLIENT_SECRET!;

export const SLACK_USER_ID = process.env.SLACK_USER_ID!;
export const SLACK_APP_ID = process.env.SLACK_APP_ID!;
export const SLACK_APP_CONFIGURATION_TOKEN = process.env.SLACK_APP_CONFIGURATION_TOKEN!;
export const SLACK_APP_CONFIGURATION_REFRESH_TOKEN =
  process.env.SLACK_APP_CONFIGURATION_REFRESH_TOKEN!;
export const SLACK_CLIENT_ID = process.env.SLACK_CLIENT_ID!;
export const SLACK_CLIENT_SECRET = process.env.SLACK_CLIENT_SECRET!;
export const SLACK_SIGNING_SECRET = process.env.SLACK_SIGNING_SECRET!;
export const SLACK_STATE_SECRET = process.env.SLACK_STATE_SECRET!;
export const SIGN_IN_WITH_SLACK_FULL_URL = `${INTERNAL_API_HOST}/slack/signinwithslack`;

export const YOUTUBE_CLIENT_ID = process.env.YOUTUBE_CLIENT_ID!;
export const YOUTUBE_CLIENT_SECRET = process.env.YOUTUBE_CLIENT_SECRET!;
