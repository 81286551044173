import { Avatar, Box, CircularProgress, Typography } from '@mui/material';
import { AUTHORIZED_USERS } from 'common/env';
import React from 'react';
import { useAuth } from 'src/auth/provider';
import { Logout } from 'src/components/auth/Logout';
import { SignInWithSlackButton } from 'src/components/auth/SignInWithSlackButton';
import { useAdminOauthLinkQuery, useOauthLinkQuery } from 'src/web-types';

const OauthLink: React.FC<{ platform: string; text: string }> = ({ platform, text }) => {
  let data, error, loading, success, url;
  if (['youtube', 'spotify'].includes(platform)) {
    let { data, loading, error } = useAdminOauthLinkQuery({ variables: { platform } });
    success = data?.adminOauthLink?.success;
    url = data?.adminOauthLink?.url;
  } else {
    let { data, loading, error } = useOauthLinkQuery({ variables: { platform } });
    success = data?.oauthLink?.success;
    url = data?.oauthLink?.url;
  }

  if (loading) return <CircularProgress />;
  if (error || !success) return null;

  return (
    <Typography variant="body1" sx={{ fontWeight: 600, mr: '20px' }}>
      <a style={{ color: 'white', textDecoration: 'none' }} href={url || ''}>
        {text}
      </a>
    </Typography>
  );
};

export const HeaderLinks: React.FC = () => {
  const { user, loading } = useAuth();
  if (loading) return <CircularProgress />;

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <OauthLink platform={'installSlack'} text={'Install Slacktunes'} />
        {user && AUTHORIZED_USERS.includes(user.userId) ? (
          <>
            <OauthLink platform={'spotify'} text={'Get Spotify Creds'} />
            <OauthLink platform={'youtube'} text={'Get Youtube Creds'} />
          </>
        ) : null}
        {user ? (
          <>
            <Logout />
            <Avatar src={user.picture || ''} alt={user.name.slice(0).toLowerCase()} />
          </>
        ) : (
          <SignInWithSlackButton />
        )}
      </Box>
    </Box>
  );
};
