import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { INTERNAL_API_HOST, NODE_ENV } from 'common/env';
import React from 'react';
import { loadState } from 'src/auth/localState';

const httpLink = new HttpLink({ uri: `${INTERNAL_API_HOST}/graphql` });

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const { operationName, variables } = operation;
  console.error(
    `[Apollo error]: Operation: ${operationName}, Variables: ${JSON.stringify(variables)}`
  );
  if (graphQLErrors) {
    graphQLErrors.map(({ message, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`)
    );
  }
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  const localState = loadState();

  if (localState && localState.token) {
    return {
      headers: {
        ...headers,
        authorization: `${localState.token}`,
      },
    };
  }

  return {
    headers,
  };
});

export const AppApolloProvider: React.FC = (props) => {
  const client = new ApolloClient({
    connectToDevTools: NODE_ENV === 'development',
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
