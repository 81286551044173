import { AppBar, CircularProgress, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from 'src/auth/provider';
import { AppApolloProvider } from 'src/components/AppApolloProvider';
import { AppThemeProvider } from 'src/components/AppThemeProvider';
import { OauthCapture } from 'src/components/auth/OauthCapture';
import { BillingProfilePage } from 'src/components/billing/BillingProfilePage';
import { HeaderLinks } from 'src/components/header/HeaderLinks';
import { Welcome } from 'src/components/Welcome';

const _Routes: React.FC = () => {
  const { getMe, loading, user } = useAuth();
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (!hasFetched && getMe) {
      getMe && getMe();
      setHasFetched(true);
    }
  }, [loading, user, getMe, hasFetched]);

  if (loading || !hasFetched) return <CircularProgress />;

  return (
    <>
      <AppBar sx={{ p: 1 }}>
        <Container
          maxWidth="lg"
          sx={{ display: 'flex', alignItems: 'center', justifyConten: 'space-between' }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
              flexGrow: 1,
              '&:hover': { cursor: 'pointer' },
            }}
          >
            <Link style={{ color: 'white', textDecoration: 'none' }} to="/">
              Slacktunes
            </Link>
          </Typography>
          <HeaderLinks />
        </Container>
      </AppBar>
      <Routes>
        <Route path="/" element={user ? <BillingProfilePage /> : <Welcome />} />
        <Route path="/oauth-exchange/:platform" element={<OauthCapture />} />
        <Route element={<div>Not found</div>} />
      </Routes>
    </>
  );
};

export const App: React.FC = () => (
  <BrowserRouter>
    <AppThemeProvider>
      <AppApolloProvider>
        <AuthProvider>
          <_Routes />
        </AuthProvider>
      </AppApolloProvider>
    </AppThemeProvider>
  </BrowserRouter>
);
