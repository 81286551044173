import { Box, CircularProgress, Container, Grid, Paper, Typography } from '@mui/material';
import { INTERNAL_API_HOST } from 'common/env';
import dayjs from 'dayjs';
import React from 'react';
import { DATE_FORMAT } from 'src/constants';
import { BillingProfile, useGetBillingProfileQuery } from 'src/web-types';
import { BillingScheduleSection } from './BillingScheduleSection';
import { PaymentHistory } from './PaymentHistory';
import { PaymentMethodSection } from './PaymentMethodSection';

const FreeTrialSection: React.FC<{ billingProfile: BillingProfile }> = ({ billingProfile }) => {
  const freeTrialDate = new Date(billingProfile.freeTrialEnds);
  const freeTrialActive = dayjs().isBefore(freeTrialDate);

  return freeTrialActive ? (
    <Paper sx={{ p: 1, mb: 1 }}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography sx={{ fontWeight: 600 }} variant="h5">
            Free trial active!
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">--</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">
            {`expires ${dayjs(freeTrialDate).format(DATE_FORMAT)}`}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  ) : null;
};

export const BillingProfilePage: React.FC = () => {
  const { data, loading, error } = useGetBillingProfileQuery();

  if (error) return <Box sx={{ mt: 10 }}>Internal Error</Box>;

  const billingProfile = data?.getBillingProfile;

  return (
    <Container maxWidth="lg" sx={{ mb: 10, mt: 10 }}>
      <Paper sx={{ padding: 1, mb: 1 }}>
        <Box sx={{ margin: '10px 0' }}>
          <Typography variant="h6">Team</Typography>
          <h1>{billingProfile?.teamName}</h1>
        </Box>
      </Paper>
      {loading ? (
        <CircularProgress />
      ) : billingProfile ? (
        <>
          <FreeTrialSection billingProfile={billingProfile} />
          <BillingScheduleSection billingProfile={billingProfile} />
          <PaymentMethodSection billingProfile={billingProfile} />
          <PaymentHistory billingProfile={billingProfile} />
        </>
      ) : (
        <Box>
          <Typography>
            Looks like you haven't installed Slacktunes to your workspace yet.
          </Typography>
          <a href={`${INTERNAL_API_HOST}/slack/install`}>
            <Box>Install Slacktunes into my workspace</Box>
          </a>
        </Box>
      )}
    </Container>
  );
};
