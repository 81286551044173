import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'src/components/App';
// Keep this list in sync with tsconfig.json's "lib"
import 'ts-polyfill/lib/es2016-array-include';
import 'ts-polyfill/lib/es2017-object';
import 'ts-polyfill/lib/es2017-string';
import 'ts-polyfill/lib/es2018-promise';
import 'ts-polyfill/lib/es2019-array';
import 'ts-polyfill/lib/es2019-object';
import 'ts-polyfill/lib/es2019-string';

const rootEl = document.getElementById('root');
ReactDOM.render(<App />, rootEl);
