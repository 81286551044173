import { CircularProgress } from '@mui/material';
import { WEB_HOST } from 'common/env';
import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { saveStateSlice } from 'src/auth/localState';
import { useOauthExchangeMutation } from 'src/web-types';

export const OauthCapture: React.FC = () => {
  const { platform } = useParams();
  const [oauth, { data, error }] = useOauthExchangeMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  let successMessage;

  const code = searchParams.get('code');
  const state = searchParams.get('state');

  useEffect(() => {
    setSearchParams('');
    if (code && state && platform) {
      setSearchParams('');
      oauth({ variables: { code, state, platform } });
    }
  }, []);

  const resp = data?.oauthExchange;
  if (resp?.error || error) {
    return <h1>{error?.message || resp?.error}</h1>;
  }

  if (resp?.success) {
    if (resp?.token && resp?.token.length) {
      saveStateSlice({ token: resp.token });
      window.location.href = WEB_HOST;
    } else {
      successMessage = `Successfully authed with ${platform}`;
    }
  }

  return <>{successMessage ? <h1>{successMessage}</h1> : <CircularProgress />}</>;
};
